/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layout/Layout';

type Props = {}

const LoginPage = (props: Props) => {

  let history = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  useEffect(() => {
    if(isAuthenticated){
      history("/dashboard");
    }
  }, [isAuthenticated])


  return (
    <Layout>
    <div className='w-full h-screen flex justify-center items-center'>
        <button 
                    onClick={() =>
                      loginWithRedirect()
                    }
        className="btn btn-primary">Click to login</button>
    </div>
    </Layout>
  )
}

export default LoginPage