/* eslint-disable no-cond-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
// import { tempList } from '../utils'
import { useRecoilState } from 'recoil'
import { csPlanningAtom, userAtom } from '../../state/atoms'
import axiosApi from '../../api/axiosApi'
import Layout from '../layout/Layout'
import { useAuth0 } from '@auth0/auth0-react'
import CsDashboardSplittedGrid from '../organisms/CsDashboard/CsDashboardSplittedGrid'
import CsSplittedContainer, { TCsType } from '../organisms/CsDashboardSplitted/CsSplittedContainer'


const CsDashboardSplit = () => {
  return (
      <Layout>
        <CsDashboardSplittedGrid>
          <CsSplittedContainer type={TCsType.ACUTE} />
          <CsSplittedContainer type={TCsType.PROVISION}/>
        </CsDashboardSplittedGrid>
      </Layout>
  )
}

export default CsDashboardSplit