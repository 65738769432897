import { TFuneralPlanningReason, TFuneralType } from "../resources";

export const getStyleColors = (diff:number) => {
  let value:number = process.env.REACT_APP_COLOR_SCALE ? parseFloat(process.env.REACT_APP_COLOR_SCALE) : 6
  if (diff < value ) {
    return {
      container: "from-green-600 border-green-500 text-green-500 fill-green-500",
      text: "text-green-500",
    };
  } else if (diff < value*2) {
    return {
      container: "from-lime-600 border-lime-500 text-lime-500 fill-lime-500",
      text: "text-lime-500",
    };
  } else if (diff < value*3) {
    return {
      container: "from-yellow-600 border-yellow-500 text-yellow-500 fill-yellow-500",
      text: "text-yellow-500",
    };
  } else if (diff < value*4) {
    return {
      container: "from-orange-600 border-orange-500 text-orange-500 fill-orange-500",
      text: "text-orange-500",
    };
  } else {
    return {
      container: "from-red-600 border-red-500 text-red-500 fill-red-500",
      text: "text-red-500",
    };
  }
};

export const getPriority = (priority:string) => {
  console.log(priority);
  switch (priority) {
    case " deceaseLocation: ZUHAUSE":
      return "Zuhause"
    case " deceaseLocation: KRANKENHAUS":
      return "Im Krankenhaus"
    case " deceaseLocation: PFLEGEHEIM":
      return "Im Pflegeheim";
    case " deceaseLocation: EINEM_BESTATTER":
      return "Bei einem Bestatter";
    case " deceaseLocation: GERICHTSMEDIZIN":
      return "In der Gerichtsmedizin";
    case " deceaseLocation: ANDERES":
      return "Anderes";
    default:
      return "";
  }
};

export const getPlanningReason = (planningReason:TFuneralPlanningReason) => {
  switch (planningReason) {
    case "ACUTE_BEREAVEMENT":
      return "Akuter Trauerfall";
    case "IMPENDING_BEREAVEMENT":
      return "Bevorstehender Trauerfall";
    case "PROVISION":
      return "Bestattungsvorsorge";
    default:
      return "";
  }
};

export const getFuneralType = (funeralType:TFuneralType) => {
  switch (funeralType) {
    case "BURIAL":
      return "Erdbestattung";
    case "CREMATION":
      return "Feuerbestattung";
    case "TREE_FUNERAL":
      return "Baumbestattung";
    case "SEA_FUNERAL":
      return "Donaubestattung";
    case "HOME_URN":
      return "Urne zu Hause";
    default:
      return "";
  }
};

export const funeralNames = (props:string) => {
  switch (props) {
    case "BURIAL":
      return "Erdbestattung"
    case "CREMATION":
      return "Feuerbestattung"
    case "TREE_FUNERAL":
      return "Baumbestattung"
    case "SEA_FUNERAL":
      return "Donaubestattung"
    case "HOME_URN":
      return "Urne zu Hause"
    default:
      return ""
  }
}
export const planningReasonsNames = (props:string) => {
  switch (props) {
    case "ACUTE_BEREAVEMENT":
      return "Akuter Trauerfall"
    case "IMPENDING_BEREAVEMENT":
      return "Bevorstehender Trauerfall"
    case "PROVISION":
      return "Bestattungsvorsorge"
    default:
      return ""
  }
}



// export const CsTeamMembers:Array<TTeamMember> = [
//   {
//     id: "1",
//     name: "Lukas Wurzinger",
//     image: "https://res.cloudinary.com/dkyl4jbxx/image/upload/w_300,h_300,c_fill,f_auto,q_auto/v1623161065/team_lukas_c365f852ed.png",
//     value: 22
//   },
//   {
//     id: "2",
//     name: "Kathrina Schnabl",
//     image: "https://res.cloudinary.com/dkyl4jbxx/image/upload/w_300,h_300,c_fill,f_auto,q_auto/v1623161070/team_kathi_e45d364cb8.png",
//     value: 22
//   },
//   {
//     id: "3",
//     name: "Mara Loesch",
//     image: "https://res.cloudinary.com/dkyl4jbxx/image/upload/w_300,h_300,c_fill,f_auto,q_auto/v1623161069/team_mara_895a2ba298.png",
//     value: 22
//   }
//   // ,
//   // {
//   //   id: 4,
//   //   name: "Stephanie Trausmuth",
//   //   image: "https://res.cloudinary.com/dkyl4jbxx/image/upload/w_300,h_300,c_fill,f_auto,q_auto/v1657541676/team_Stephanie_d1665c80dc.jpg"
//   // },
//   // {
//   //   id: 5,
//   //   name: "Teodora Maric",
//   //   image: "https://res.cloudinary.com/dkyl4jbxx/image/upload/w_300,h_300,c_fill,f_auto,q_auto/v1654092449/team_teodora_maric_web_c7af02dd66.jpg"
//   // },
//   // {
//   //   id: 6,
//   //   name: "Denise Holzmann",
//   //   image: "https://res.cloudinary.com/dkyl4jbxx/image/upload/w_300,h_300,c_fill,f_auto,q_auto/v1658737347/team_denise_holzmann_e40276a6b4.jpg"
//   // },
//   // {
//   //   id: 7,
//   //   name: "Maija Puukka",
//   //   image: "https://res.cloudinary.com/dkyl4jbxx/image/upload/w_300,h_300,c_fill,f_auto,q_auto/v1654093709/team_maija_pukka_web_87b09bd603.jpg"
//   // }
// ]





// export const tempList:Array<TPlanning> = [

//     {
//         orderId: "BA20437",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "HOME_URN",
//         name: "Benjamin Kapic",
//         phone: "068110791599",
//         timeStamp: "2022-09-08T08:46:12.826-03:00",
//         priority: "OTHER"
//     },
//     {
//         orderId: "BA19792",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "BURIAL",
//         name: "Bruno Bonfim",
//         phone: "06644102120",
//         timeStamp: "2022-09-08T09:51:27.452-03:00",
//         priority: "OTHER"
//     },
//     {
//         orderId: "BA72408",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "BURIAL",
//         name: "Dan Prelipcean",
//         phone: "06764864548",
//         timeStamp: "2022-09-07T07:28:21.986-03:00",
//         priority: "OTHER"
//     },
//     {
//         orderId: "CA61550",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "CREMATION",
//         name: "Franz Strümpf",
//         phone: "06605569949",
//         timeStamp: "2022-09-07T08:45:53.181-03:00",
//         priority: "OTHER"
//     },
//     {
//         orderId: "BA55803",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "CREMATION",
//         name: "Dora Kornel",
//         phone: "06605038151",
//         timeStamp: "2022-09-07T14:17:25.876-03:00",
//         priority: "OTHER"
//     },
//     {
//         orderId: "BA66091",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "TREE_FUNERAL",
//         name: "Robert Peska",
//         phone: "4369915051180",
//         timeStamp: "2022-09-07T15:23:48.383-03:00",
//         priority: "OTHER"
//     },
//     {
//         orderId: "BA58951",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "CREMATION",
//         name: "Test  Speed",
//         phone: "01234567",
//         timeStamp: "2022-09-08T13:40:47.430-03:00",
//         priority: "OTHER"
//     },
//     {
//         orderId: "BA97309",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "BURIAL",
//         name: "Alexandra Gordon",
//         phone: "06765724089",
//         timeStamp: "2022-09-08T13:50:47.430-03:00",
//         priority: "HOME"
//     },
//     {
//         orderId: "CA82685",
//         planningReason: "ACUTE_BEREAVEMENT",
//         funeralType: "BURIAL",
//         name: "werner duda",
//         phone: "+436642127172",
//         timeStamp: "2022-09-08T14:00:47.430-03:00",
//         priority: "OTHER"
//     },
//     {
//         orderId: "BB50721",
//         planningReason: "IMPENDING_BEREAVEMENT",
//         funeralType: "CREMATION",
//         name: "Leopold Hintringer",
//         phone: "06765591369",
//         timeStamp: "2022-09-08T14:10:47.430-03:00",
//         priority: "HOME"
//     },
//     {
//         orderId: "BB68589",
//         planningReason: "IMPENDING_BEREAVEMENT",
//         funeralType: "CREMATION",
//         name: "Philipp Platz",
//         phone: "06602911987",
//         timeStamp: "2022-09-08T14:20:47.430-03:00",
//         priority: "OTHER"
//     }
// ]