import React, { useEffect, useState } from 'react'

type Props = {}

const Clock = (props: Props) => {

  const [time, setTime] = useState(
    {hour: "", minute:""})

  useEffect(() => {

    setInterval(() => {

      const dateObject = new Date()

      const hour = dateObject.getHours()
      const minute = dateObject.getMinutes()

      const currentTime = {
        hour: hour.toString(),
        minute: minute < 10 ? `0${minute.toString()}` : minute.toString()
      }
      setTime(currentTime)
    }, 1000)

  }, [])



  return (
    <div className='w-48 px-2 bg-benu-3 h-full flex justify-center text-black items-center italic text-5xl font-bold tracking-tight'>
      {time.hour}<span className='animate-pulse mb-2 px-0.5'>:</span>{time.minute}
    </div>
  )
}

export default Clock