import React from "react";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "../auth0/ProtectedRoute";
import CsDashboard from "../components/templates/CsDashboard";
import CsDashboardSplit from "../components/templates/CsDashboardSplit";
import MainDashboard from "../components/templates/MainDashboard";
import LoginPage from "../pages/main/LoginPage";

type Props = {};

export const RouterComponent: React.FC = (props: Props) => {
  return (
    <Routes>
        <Route index element={<LoginPage/>} />
        <Route element={<ProtectedRoute component={MainDashboard } />} path="dashboard" />  
        <Route element={<ProtectedRoute component={CsDashboard} />} path="acute" />  
        <Route element={<ProtectedRoute component={CsDashboard} />} path="provision" />
        <Route element={<ProtectedRoute component={CsDashboardSplit} />} path="split" />
    </Routes>
  );
};
