import React from "react";
import { useRecoilValue } from "recoil";
import { csPlanningAtom } from "../../../state/atoms";
import TeamItem from "../../atoms/CsDashboard/TeamItem";
// import { CsTeamMembers } from '../../utils'

type Props = {};

const TeamArea = (props: Props) => {
  const csPlanning = useRecoilValue(csPlanningAtom);
  return (
    <div className="flex flex-row flex-grow bg-black h-full">
      {csPlanning.agents.map((item, index) => (
          <TeamItem {...item} />
      ))}
    </div>
  );
};

export default TeamArea;
