import React from 'react'
import { getStyleColors } from '../../utils'
import Clock from '../svgs/Clock'

export const DayClock = ({values}) => {
  return (
<div className={`flex flex-col justify-center items-center space-y-1 text-base font-bold tracking-tight text-white`}>
<p className={"text-6xl font-extrabold text-center w-[180px] italic " }>
        {values.day}<span className='text-5xl font-light'>d</span>
      </p>
  <p className={"text-3xl font-extrabold text-center w-[180px] italic" }>
        {values.hour}<span className='text-2xl font-normal'>h</span>
        {values.minute}<span className='text-2xl font-normal'>m</span>
      </p>
</div>

  )
}
export const HourClock = ({values}) => {
  let color = getStyleColors(values.diffMin);
  return (
    <div className={`flex flex-col justify-center items-center space-y-1 text-base font-bold tracking-tight ${color.text} `}>
        <div
          className="radial-progress border-black bg-black bg-opacity-30 border-opacity-30 shadow-2xl border-4 border-solid opacity-100"
          style={{
            "--value": values.hour * 4.16,
            "--size": "5rem",
            "--thickness": "0.2rem",
          }}
        >
        <div
          className="radial-progress border-black bg-black bg-opacity-30 border-opacity-30 shadow-2xl border-4 border-solid opacity-100"
          style={{
            "--value": values.minute / 0.6,
            "--size": "4rem",
            "--thickness": "0.25rem",
          }}
        >
          <Clock size={16} />
      </div>
      </div>
      <p className={"text-4xl font-extrabold text-center w-[180px] italic text-white" }>
        {values.hour}<span className='text-2xl font-normal'>h</span>
        {values.minute}<span className='text-2xl font-normal'>m</span>
      </p>
    </div>
  )
}
export const MinuteClock = ({values}) =>{
  let color = getStyleColors(values.diffMin);

  return (
    <div className={`flex flex-col justify-center items-center space-y-1 text-base font-bold tracking-tight  ${color.text} `}>
      <div
        className="radial-progress border-black bg-black bg-opacity-30 border-opacity-30 shadow-2xl border-4 border-solid opacity-100"
        style={{
          "--value": values.minute / 0.6,
          "--size": "5rem",
          "--thickness": "0.5rem",
        }}
      >
          <Clock size={20} />
      </div>
      <p className={"text-5xl font-extrabold text-center w-[180px] italic text-white" }>{values.minute}<span className='text-2xl font-normal'>m</span></p>
    </div>
  )
}