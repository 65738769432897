import React from 'react'
import Clock from '../../molecules/CsDashboard/Clock'
import FooterLogo from '../../molecules/CsDashboard/FooterLogo'
import Queue from '../../molecules/CsDashboard/Queue'
import TeamArea from '../../molecules/CsDashboard/TeamArea'

type Props = {}

const Footer = (props: Props) => {
  return (
    <div className='flex flex-row justify-center items-center h-24 bg-white'>
      <FooterLogo />
      <TeamArea/>
      <Queue/>
      <Clock/>
    </div>
  )
}

export default Footer