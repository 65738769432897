import React from 'react'
import BenuLogo from '../../atoms/svgs/BenuLogo'

type Props = {}

const FooterLogo = (props: Props) => {
  return (
    <div className='w-64 bg-benu-2 h-full px-2 flex justify-center items-center text-black fill-black'>
      <BenuLogo/>
    </div>
  )
}

export default FooterLogo