import React from 'react'
import { useNavigate } from 'react-router-dom';

type Props = {
  subject: string,
  title: string,
  target: string
}

const MenuItem = ({subject, title, target}: Props) => {
  let history = useNavigate();
  return (
    <button className='flex flex-col justify-between items-start bg-purple-900 rounded-lg hover:bg-purple-600 transition-all ease-in-out duration-500 cursor-pointer p-4' onClick={()=>{
      history(target)
    }}>
      <p className='text-xl text-white opacity-50'>{subject}</p>
      <p className='text-4xl text-white font-bold'>{title}</p>
    </button>
  )
}

export default MenuItem