import React, { ReactNode } from 'react'

type Props = {
  children:ReactNode
}

const CsGridContainer = ({children}: Props) => {
  return (
    <div className='grid grid-cols-4 grid-rows-4 w-full h-full gap-4 mx-4 pb-4 '>
      {children}
    </div>
  )
}

export default CsGridContainer