import React from 'react'

type Props = {
  size: number
}

const Clock = (props: Props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_115_358)">
<path d="M24.4484 3.36594C23.9664 3.33035 23.4833 3.31253 23 3.3125V0.5C23.5528 0.50027 24.1053 0.520909 24.6566 0.561875L24.4484 3.36594ZM30.0847 4.63156C29.1831 4.28367 28.2572 4.0026 27.3144 3.79062L27.9303 1.04563C29.0075 1.2875 30.0678 1.60812 31.0972 2.0075L30.0847 4.63156ZM33.9378 6.62844C33.5357 6.36026 33.1239 6.10697 32.7031 5.86906L34.0897 3.42219C35.0513 3.96707 35.9716 4.58185 36.8431 5.26156L35.1134 7.48063C34.7321 7.18315 34.34 6.89983 33.9378 6.63125V6.62844ZM39.0959 11.6628C38.5396 10.8724 37.9258 10.1242 37.2594 9.42406L39.2956 7.48344C40.055 8.285 40.7581 9.14281 41.3966 10.0428L39.0959 11.6628ZM41.1884 15.4653C41.0038 15.0194 40.8031 14.5804 40.5866 14.1491L43.0981 12.8834C43.5948 13.8709 44.0178 14.8937 44.3637 15.9434L41.6919 16.8237C41.5407 16.3649 41.3728 15.9118 41.1884 15.4653ZM42.6791 22.5163C42.6563 21.5497 42.5623 20.5862 42.3978 19.6334L45.1681 19.1553C45.3566 20.2409 45.4662 21.3434 45.4944 22.4459L42.6819 22.5163H42.6791ZM42.3106 26.8419C42.4034 26.3638 42.4794 25.8884 42.5384 25.4075L45.3312 25.7534C45.1962 26.8507 44.9799 27.9365 44.6844 29.0019L41.9731 28.2509C42.1025 27.7869 42.215 27.3172 42.3106 26.8419ZM39.6331 33.5328C40.1506 32.7172 40.6062 31.8622 41 30.9791L43.5706 32.1181C43.1206 33.1306 42.6003 34.1037 42.0097 35.0375L39.6331 33.5328ZM36.9219 36.9219C37.265 36.5788 37.5941 36.2244 37.9063 35.8587L40.0381 37.6953C39.6771 38.1139 39.3009 38.5192 38.9103 38.9103L36.9219 36.9219Z" className='fill-current' />
<path d="M23.0002 3.3125C19.7627 3.31273 16.5752 4.11138 13.7201 5.6377C10.8649 7.16402 8.43021 9.37091 6.63165 12.0629C4.83309 14.7549 3.72616 17.8489 3.40891 21.0708C3.09166 24.2927 3.57388 27.5432 4.81286 30.5343C6.05184 33.5253 8.00934 36.1647 10.512 38.2186C13.0146 40.2725 15.9851 41.6775 19.1604 42.3091C22.3357 42.9408 25.6178 42.7797 28.716 41.84C31.8141 40.9003 34.6327 39.211 36.9221 36.9219L38.9106 38.9103C36.2942 41.5281 33.0725 43.4602 29.531 44.5353C25.9894 45.6104 22.2374 45.7954 18.6073 45.0739C14.9772 44.3523 11.5811 42.7465 8.71991 40.3988C5.85873 38.051 3.62081 35.0338 2.20443 31.6144C0.788054 28.195 0.236952 24.479 0.599955 20.7958C0.962959 17.1125 2.22886 13.5756 4.28549 10.4985C6.34213 7.42138 9.126 4.89906 12.3904 3.15501C15.6549 1.41096 19.2991 0.499019 23.0002 0.500001V3.3125Z" className='fill-current' />
<path d="M21.5938 8.9375C21.9667 8.9375 22.3244 9.08566 22.5881 9.34938C22.8518 9.6131 23 9.97079 23 10.3438V24.9969L32.135 30.2169C32.4494 30.4065 32.6772 30.7117 32.7696 31.0671C32.862 31.4224 32.8118 31.7999 32.6296 32.1187C32.4474 32.4375 32.1477 32.6724 31.7946 32.7732C31.4416 32.874 31.063 32.8327 30.74 32.6581L20.8962 27.0331C20.681 26.9102 20.5021 26.7325 20.3776 26.5182C20.2532 26.3038 20.1876 26.0604 20.1875 25.8125V10.3438C20.1875 9.97079 20.3357 9.6131 20.5994 9.34938C20.8631 9.08566 21.2208 8.9375 21.5938 8.9375Z" className='fill-current' />
</g>
<defs>
<clipPath id="clip0_115_358">
<rect width="45" height="45" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>
  )
}

export default Clock