import React from 'react'
import { TTeamMember } from '../../../resources'
import TeamItem from '../../atoms/CsDashboard/TeamItem'
import TeamItemSplitted from '../../atoms/CsDashboard/TeamItemSplitted'

type Props = {
  title: string,
  queue: number,
  members: TTeamMember[]
}

const HorizontalHelper = (props: Props) => {
  return (
    <div className='flex flex-col justify-start items-start w-full h-full'>
      <div className='p-2 font-bold italic text-5xl w-full bg-benu-red2'>{props.title}</div>
      <div className='p-2 font-bold italic text-7xl w-full bg-benu-red1 flex flex-row space-x-4 items-center'>
        <p>{props.queue}</p>
        <p className='text-5xl'>{props.queue > 1 ? "Leads" : "Lead"} </p>
      </div>
      <div className='grid grid-cols-2 grid-row-5 gap-1 w-full'>
        {props.members.map((item, index)=> <TeamItemSplitted {...item} />)}
      </div>

    </div>
  )
}

export default HorizontalHelper