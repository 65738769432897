


export type TFuneralType = 
| "BURIAL"
| "CREMATION"
| "TREE_FUNERAL"
| "SEA_FUNERAL"
| "HOME_URN"


export type TPriority = 
| "HOME"
| "OTHER"

export type TTeamMember = {
  id: string,
  name: string,
  image: string,
  value: number
}

export type TFuneralPlanningReason =
| "ACUTE_BEREAVEMENT"
| "IMPENDING_BEREAVEMENT"
| "PROVISION"

export type TPlanningPriority =
| "ACUTE_BEREAVEMENT"
| "IMPENDING_BEREAVEMENT"
| "PROVISION"

export type TPlanning = {
  orderId: string,
  funeralType: TFuneralType,
  planningReason: TFuneralPlanningReason,
  name: string,
  phone: string | null,
  timestamp: string,
  priority: TPriority | null,
  deceasedLocation: EDeceasedLocation
}

export const enum EDeceasedLocation {
  ZUHAUSE ="ZUHAUSE",
  PFLEGEHEIM = "PFLEGEHEIM",
  OTHER = "OTHER"
}

export type TCsPlanning = {
    isLoaded: false,
    leads: TPlanning[],
    agents: TTeamMember[]
}