import React from 'react'
import { useRecoilValue } from 'recoil'
import { csPlanningAtom } from '../../../state/atoms'
// import { tempList } from '../../utils'

type Props = {}

const Queue = (props: Props) => {
  const csPlanning = useRecoilValue(csPlanningAtom)
  return (
    <div className='w-64 bg-benu-red1 h-full flex flex-row px-2 justify-center items-center italic text-4xl font-bold tracking-tighter leading-7 space-x-2'>
      <p className='text-[72px] leading-3 text-right'>{csPlanning.leads.length}</p>
      <p>{csPlanning.leads.length > 1 ? "Leads" : "Lead"}</p>
    </div>
  )
}

export default Queue