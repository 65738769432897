import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const CsDashboardSplittedGrid = ({
  children
}: Props) => {
  return (
    <div className='grid grid-col-1 h-screen custom-width justify-between'>
      {children}
    </div>
  )
}

export default CsDashboardSplittedGrid