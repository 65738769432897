import Layout from '../layout/Layout'
import MenuItem from '../molecules/Dashboard/MenuItem'
import Title from '../organisms/CsDashboard/Title'
import CsDashboardGrid from '../organisms/CsDashboard/CsDashboardGrid'
import { useAuth0 } from '@auth0/auth0-react'

const MainDashboard = () => {
  const { logout } = useAuth0();

  return (
    <Layout>
      <CsDashboardGrid>
        <Title>Dashboards</Title>
        <div className='grid grid-cols-6 grid-rows-6 gap-4 m-4 flex-grow'>
          <MenuItem
            subject='CS-TEAM'
            title='Provision'
            target='/provision'/>
          <MenuItem
            subject='CS-TEAM'
            title='Acute'
            target='/acute'/>
          <MenuItem
            subject='CS-TEAM'
            title='Split'
            target='/split'/>
            <button onClick={()=>{
              logout({ returnTo: window.location.origin })
            }}>Logout</button>
        </div>
      </CsDashboardGrid>
    </Layout>
  )
}

export default MainDashboard