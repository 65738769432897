import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const CsDashboardGrid = ({
  children
}: Props) => {
  return (
    <div className='flex flex-col h-screen w-screen flex-grow justify-between'>
      {children}
    </div>
  )
}

export default CsDashboardGrid