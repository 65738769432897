/* eslint-disable no-cond-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Footer from '../organisms/CsDashboard/Footer'
import Title from '../organisms/CsDashboard/Title'
import CsDashboardGrid from '../organisms/CsDashboard/CsDashboardGrid'
import CsGridContainer from '../organisms/CsDashboard/CsGridContainer'
import OfferItem from '../molecules/CsDashboard/OfferItem'
// import { tempList } from '../utils'
import { useRecoilState } from 'recoil'
import { csPlanningAtom, userAtom } from '../../state/atoms'
import axiosApi from '../../api/axiosApi'
import Layout from '../layout/Layout'
import { useAuth0 } from '@auth0/auth0-react'


const CsDashboard = () => {
  const [csPlanning, setCsPlanning] = useRecoilState(csPlanningAtom)

  const [userInfo, setUserInfo] = useRecoilState(userAtom)
  const auth0 = useAuth0()

  const [title ,setTitle] = useState("")

  useEffect(() => {
    async function storeUserInfo() {
      if(auth0.isAuthenticated){
        axiosApi.defaults.headers.common['Authorization'] = `Bearer ${await auth0.getAccessTokenSilently()}`;
        await setUserInfo({...auth0.user, session_token:await auth0.getAccessTokenSilently()})
      }
    }
    storeUserInfo()
  }, [auth0])


  useEffect(() => {
    console.log(userInfo)
    if(userInfo.session_token){
      loadCsPlanning()
    }
  }, [userInfo])

  useEffect(() => {
    const interval = setInterval(() => {
      loadCsPlanning()
    }, 20000);
    return () => clearInterval(interval);
  }, []);


  const loadCsPlanning = async () => {
    let url = ""
    if(window.location.pathname === "/provision"){
      url = "/dashboard/provision"
      setTitle("Vorsorge Leads")
    }else if(window.location.pathname === "/acute"){
      url = "/dashboard/acute"
      setTitle("Akut Leads")
    }
    await axiosApi.get(url, {})
    .then(res => {
      res.data.isLoaded = true;
      setCsPlanning(res.data)
      }
    )
  }  

  return (
      <Layout>
      <CsDashboardGrid>
        <Title>{title}</Title>
        <div className='flex'>
          <CsGridContainer>
            {csPlanning.leads.slice(0, 16).map((item, index) => {
              return <OfferItem {...item} key={index}/>
            })}
          </CsGridContainer>
        </div>
        <Footer />
      </CsDashboardGrid>
      </Layout>
  )
}

export default CsDashboard