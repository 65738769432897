import { atom } from "recoil";
import { TCsPlanning, TPlanning, TTeamMember } from "../resources";

export const userAtom = atom<any>({
  key: "user",
  default: {}
})

export const csPlanningAtom = atom<TCsPlanning>({
  key: "csPlanning",
  default:{
    isLoaded: false,
    leads: [] as TPlanning[],
    agents: [] as TTeamMember[]
  }
})
export const csPlanningAcuteAtom = atom<TCsPlanning>({
  key: "csAcutePlanning",
  default:{
    isLoaded: false,
    leads: [] as TPlanning[],
    agents: [] as TTeamMember[]
  }
})
export const csPlanningProvisionAtom = atom<TCsPlanning>({
  key: "csProvisionPlanning",
  default:{
    isLoaded: false,
    leads: [] as TPlanning[],
    agents: [] as TTeamMember[]
  }
})