import React from "react";
import { TTeamMember } from "../../../resources";

const TeamItemSplitted = ({ id, name, image, value }: TTeamMember) => {
  return (
    <div className="bg-black flex w-full h-20 relative">
      {image ? (
        <img
          src={image}
          className="w-full h-full object-cover opacity-20 relative"
          alt={name}
        />
      ) : (
        <img
          src={
            "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1628107234/mementry/mementry_avatar.png"
          }
          className="w-full h-full object-cover opacity-20 relative"
          alt={name}
        />
      )}
      {/* <img src={image} className="w-full h-full object-cover opacity-20 relative" alt={name}/> */}
      <div className="absolute flex flex-row items-start justify-center bottom-0 z-10 flex-grow space-x-3">
        {image ? (
          <img
            src={image}
            className="h-20 aspect-square object-cover relative shadow-lg"
            alt={name}
          />
        ) : (
          <img
            src={
              "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1628107234/mementry/mementry_avatar.png"
            }
            className="h-20 aspect-square object-cover relative shadow-lg"
            alt={name}
          />
        )}
        <div className="flex flex-col items-start justify-center flex-grow">
          <p className="text-left italic font-bold text-6xl -mb-2">{value}</p>
          <p className="text-bold text-lg italic leading-5-mb-4 ">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamItemSplitted;
