import { differenceInMinutes } from 'date-fns'
import React from 'react'
import { TPlanning } from '../../../resources'
import Boat from '../../atoms/svgs/Boat'
import Coffin from '../../atoms/svgs/Coffin'
import Tree from '../../atoms/svgs/Tree'
import Urn from '../../atoms/svgs/Urn'
import { DayClock, HourClock, MinuteClock } from '../../atoms/tasks/MultiClockComponent'
import { getStyleColors, planningReasonsNames } from '../../utils'

const OfferItem = (props:TPlanning) => {
  const {orderId, name, planningReason, priority, timestamp} = props
  let values = calculateTime(timestamp)
  let color = getStyleColors(values.diffMin);

  return (
    <div className='relative'>
       <div className={`
        relative 
        bg-gradient-to-b 
        border-solid to-black 
        w-full h-full  animate-pulse border-4 rounded-lg 
        ${color.container}
        `}>
          <GetIconBg {...props} />
       </div>
       <div className='absolute w-full h-full top-0 z-10 p-4 flex-grow space-x-0.5 flex flex-row'>
        <div className='h-full flex flex-grow flex-col text-3xl shadow-md font-bold'>
          <p>{orderId}</p>
          {/* <p className='flex text-2xl'>{funeralNames(funeralType)}</p> */}
          <p className='flex flex-grow text-xl'>{planningReasonsNames(planningReason)}</p>
          <p className='text-4xl font-bold'>{name}</p>
        </div>
        <div className={`h-full w-32 flex justify-center items-center ${priority ? "pt-8" : "pt-0"}`}>
          <GetClock {...props}/>
        </div>
       </div>
       {priority && priority === "HOME" &&
        <div className='absolute w-full h-full top-0 z-10 flex flex-row justify-end items-start p-1'>
            <div className='w-auto py-1 px-4 bg-white text-red-500 text-xl font-bold rounded-tr-lg rounded-bl-xl uppercase'>
            Zuhause
            </div>
        </div>
      }
    </div>
  )
}
const calculateTime = (timestamp:string) => {
  console.log("TIMESTAMP: ", timestamp)
  const diffMin = differenceInMinutes(new Date(Date.now()), new Date(timestamp))
  const values = {
    diffMin: diffMin,
    day: Math.floor(diffMin / (24*60)),
    hour: Math.floor((diffMin % (24*60))/60),
    minute: Math.floor((diffMin % (24*60))%60)
  }
  return values
}

const GetClock = ({timestamp, priority}:TPlanning) => {
  let values = calculateTime(timestamp)
  if(values.day > 0){
    return <DayClock values={values} />
  }else if(values.hour > 0){
    return <HourClock values={values} />
  }else{
    return <MinuteClock values={values} />
  }
}



const GetIconBg = (props:TPlanning) => {
  switch (props.funeralType) {
    case "CREMATION":
    case "HOME_URN":
      return <Urn />
    case "BURIAL":
      return <Coffin />
    case "SEA_FUNERAL":
      return <Boat />
    case "TREE_FUNERAL":
      return <Tree />
    default:
      return <></>
  }
}

export default OfferItem