import React from 'react';
// import MainDashboard from './components/templates/MainDashboard';
import { RouterComponent } from './routes/RouterComponent';
function App() {
  return (

      <RouterComponent/>
  );
}

export default App;
