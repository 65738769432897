/* eslint-disable no-cond-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import axiosApi from '../../../api/axiosApi'
import { csPlanningAcuteAtom, csPlanningAtom, csPlanningProvisionAtom, userAtom } from '../../../state/atoms'
import HorizontalHelper from '../../molecules/CsDashboard/HorizontalHelper'
import OfferItem from '../../molecules/CsDashboard/OfferItem'
import OfferSmallItem from '../../molecules/CsDashboard/OfferSmallItem'

export enum TCsType {
  ACUTE = "acute",
  PROVISION = "provision"
}

type CsConfig = {
  title: string,
  url: string,
}

type Props = {
  type: TCsType
}


const getPlanningConfig = (type: TCsType): CsConfig => {
  if (type === TCsType.ACUTE) {
    return {
      title: "Akut Leads",
      url: "/dashboard/acute"
    }
  } else {
    return {
      title: "Vorsorge Leads",
      url: "/dashboard/provision"
    }
  }
}

const CsSplittedContainer = ({ type }: Props) => {
  const [csPlanning, setCsPlanning] = useRecoilState(type === TCsType.ACUTE ? csPlanningAcuteAtom : csPlanningProvisionAtom)
  const [userInfo, setUserInfo] = useRecoilState(userAtom)
  const auth0 = useAuth0()


  useEffect(() => {
    async function storeUserInfo() {
      if (auth0.isAuthenticated) {
        axiosApi.defaults.headers.common['Authorization'] = `Bearer ${await auth0.getAccessTokenSilently()}`;
        await setUserInfo({ ...auth0.user, session_token: await auth0.getAccessTokenSilently() })
      }
    }
    storeUserInfo()
  }, [auth0])


  useEffect(() => {
    console.log(userInfo)
    if (userInfo.session_token) {
      loadCsPlanning()
    }
  }, [userInfo])

  useEffect(() => {
    const interval = setInterval(() => {
      loadCsPlanning()
    }, 60000);
    return () => clearInterval(interval);
  }, []);


  const loadCsPlanning = async () => {
    await axiosApi.get(getPlanningConfig(type).url, {})
      .then(res => {
        res.data.isLoaded = true;
        setCsPlanning(res.data)
      }
      )
  }


  return (
    <div className='grid grid-cols-5 grid-rows-3 gap-5 custom-width p-2 h-full'>
      <div className='row-span-3 w-full flex flex-grow'>
        <HorizontalHelper
          title={getPlanningConfig(type).title}
          queue={csPlanning.leads.length}
          members={csPlanning.agents}
        />
      </div>
      {csPlanning.leads.slice(0, 12).map((item, index) => <OfferSmallItem {...item} />).reverse()}
      {csPlanning.leads.length === 0 && <div className='space-y-4 col-span-4 row-span-3 flex flex-col text-6xl font-bold justify-center items-center'>
        <div className='h-64 w-64'>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 862 862"
          >
            <g clipPath="url(#clip0_918_482)">
              <path
                fill="#974154"
                d="M430.999 861.997c238.033 0 430.998-192.965 430.998-430.998C861.997 192.965 669.032 0 430.999 0 192.965 0 0 192.965 0 430.999c0 238.033 192.965 430.998 430.999 430.998z"
              ></path>
              <path
                fill="#621C2B"
                d="M708.544 339.824l-460.91 358.057 163.524 163.646c6.58.299 13.189.473 19.844.473 218.247 0 398.576-162.224 427.054-372.664L708.544 339.824z"
              ></path>
              <path
                fill="#FFCD02"
                d="M720.83 360.564a55.889 55.889 0 00-42.662-37.623l-132.95-25.416-65.251-118.584a55.89 55.89 0 00-97.937 0l-65.25 118.584-132.948 25.416a55.888 55.888 0 00-42.665 37.623 55.905 55.905 0 0012.396 55.522l92.617 98.706-16.912 134.297a55.889 55.889 0 0022.606 52.196 55.886 55.886 0 0056.628 5.367l122.497-57.586 122.496 57.586a55.786 55.786 0 0023.77 5.308 55.862 55.862 0 0032.855-10.675 55.893 55.893 0 0022.606-52.201l-16.915-134.295 92.62-98.709a55.879 55.879 0 0012.399-55.516z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_918_482">
                <path fill="#fff" d="M0 0H862V862H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </div>
        <p>Let's focus on the Opps to catch</p>
      </div>}
    </div>
  )
}

export default CsSplittedContainer