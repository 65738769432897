import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

function Title({children}: Props) {
  return (
    <div className='flex flex-row p-4 italic font-bold text-6xl mb-2'>{children}</div>
  )
}

export default Title