/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import axiosApi from '../../api/axiosApi'
import { userAtom } from '../../state/atoms'

type Props = {
  children: React.ReactNode
}

const Layout = ({children}:Props) => {
  const [userInfo, setUserInfo] = useRecoilState(userAtom)
  const auth0 = useAuth0()

  useEffect(() => {
    async function storeUserInfo() {
      if(auth0.isAuthenticated){
        axiosApi.defaults.headers.common['Authorization'] = `Bearer ${await auth0.getAccessTokenSilently()}`;
        // console.log({...auth0.user, session_token:await auth0.getAccessTokenSilently()})
        setUserInfo({...auth0.user, session_token:await auth0.getAccessTokenSilently()})
      }
    }
    storeUserInfo()
  }, [auth0])

  return (
    <>
        {
          userInfo?.sub ? 
            <>{children}</>
        :<></>
        }
      </>
  )
}

export default Layout